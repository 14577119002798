// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapMutations } = createNamespacedHelpers("inbox");

// design
import {
  iconAccount,
  iconCalendar,
  iconDotsHorizontal,
  iconManager
} from "@/design/icon/iconConst";

// filters
import {
  toLocalDateTimeString,
  toShortLocalString
} from "@/filters/dateFilter";

// model
import {
  dueDateColorClass,
  dueDateMessage,
  dueDateTextClass,
  findTaskPriority,
  findTaskState,
  findUserTaskType
} from "@/model/workflow/task/taskModel";
import {
  findProjectOperation,
  projectOperation
} from "@/model/workflow/project/projectModel";
import {
  createActionResultError,
  createActionResultSuccess
} from "@/model/action/actionModel";
import { UPDATE_TASK_ASSIGNEE } from "@/store/inbox/mutation-types";
import { canReassignTask } from "@/services/inbox/taskService";

export const taskMixin = {
  components: {
    TaskNotes: () => import("@/components/task/TaskNotes"),
    AssignUserDialog: () =>
      import("@/components/task/dialogs/AssignUserDialog.vue")
  },
  data() {
    return {
      iconMore: iconDotsHorizontal,
      iconCalendar: iconCalendar,
      iconUser: iconAccount,
      iconManager: iconManager,
      visibleDialogAssignUser: false
    };
  },
  props: {
    /**
     * @type {{taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, created: string, effective: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}
     */
    task: undefined,

    /**
     * is Report
     */
    isReport: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * User Task id
     * @return {Number|number}
     */
    taskId() {
      return this.task?.taskId ?? -1;
    },

    /**
     * User Task task name
     * @return {String|string}
     */
    taskName() {
      return this.task?.name ?? "";
    },

    /**
     * task Type Id
     * @return {*|number}
     */
    taskTypeId() {
      return this.task?.typeId ?? -1;
    },

    /**
     * user Task Type
     * @return {{id: number, name: string, commandAccept: {label: string, icon: string, color: {variantType: string, variantNumber: number, name: string}}, commandReject: {label: string, icon: string, color: {variantType: string, variantNumber: number, name: string}}}}
     */
    userTaskType() {
      return findUserTaskType(this.taskTypeId);
    },

    /**
     * task's Workflow Project id
     * @return {Number|number}
     */
    projectId() {
      return this.task?.projectId ?? -1;
    },

    /**
     * task's  Workflow project Name
     * @return {String|string}
     */
    projectName() {
      return this.task?.projectName ?? "";
    },

    /**
     * task's Workflow Template id
     * @return {Number|number}
     */
    templateId() {
      return this.task?.templateId ?? -1;
    },

    /**
     * task's Workflow Template name
     * @return {String|string}
     */
    templateName() {
      return this.task?.templateName;
    },

    /**
     * Associated task's Workflow record name
     * @return {String|string}
     */
    subject() {
      return this.task?.recordName;
    },

    /**
     * comments
     */
    comment: {
      get() {
        return this.task?.comment;
      },
      set(value) {
        if (this.task) {
          this.task.comment = value;
        }
      }
    },

    /**
     * Task notes
     * @return {{id: number, created: string, taskId: number, userId: number, userName: string}[]}
     */
    notes() {
      return this.task?.notes ?? [];
    },

    /**
     * task due Date
     * @return {string|string}
     */
    dueDate() {
      return this.task?.dueDate ? toShortLocalString(this.task?.dueDate) : "";
    },

    /**
     * is Due?
     * @return {Boolean|boolean}
     */
    isDue() {
      return this.task?.isDue ?? false;
    },

    /**
     * is Task Overdue
     * @return {Boolean|boolean}
     */
    isOverdue() {
      return this.task?.isOverdue ?? false;
    },

    /**
     * is Task Milestone
     * @return {Boolean|boolean}
     */
    isMilestone() {
      return this.task?.isMilestone ?? false;
    },

    /**
     * task priority
     * @return {Number|number}
     */
    priority() {
      return this.task?.priority ?? -1;
    },

    /**
     * priority Text
     * @return {String} priority Text
     */
    priorityText() {
      return findTaskPriority(this.task?.priority ?? -1)?.text ?? "";
    },

    assignmentMethod() {
      return this.task?.assignmentMethodTypeText;
    },

    /**
     * Assignee
     * @return {String|string|undefined}
     */
    assignee() {
      const actorId = this.task?.actorId ?? -1;
      const actorName = this.task?.actorName;

      // make sure whether a user has been assigned (manually/auto/self)
      if (actorId > 0) {
        // a user has been assigned (manually/auto/self)
        return actorName;
      }

      return this.task?.assignedTo;
    },

    /**
     * name of project Manager
     * @return {String|string}
     */
    projectManager() {
      return this.task?.projectManager ?? "";
    },

    /**
     * task Created date and time
     * @return {*}
     */
    taskCreated() {
      return this.task?.created ? toLocalDateTimeString(this.task.created) : "";
    },

    /**
     * task Effective date and time
     * @return {*}
     */
    taskEffective() {
      return this.task?.effective
        ? toLocalDateTimeString(this.task.effective)
        : "";
    },

    /**
     * task status
     * @return {string} task status
     */
    taskStatus() {
      return findTaskState(this.task?.status ?? -1)?.name;
    },

    /**
     * status Changed
     * @return {string|string}
     */
    statusChanged() {
      return this.task.statusChanged
        ? toLocalDateTimeString(this.task.statusChanged)
        : "";
    },

    /**
     * task Duration
     * @return {string|undefined} task Duration
     */
    taskDuration() {
      return this.task?.duration;
    },

    /**
     *
     * @return {String|string|undefined}
     */
    dueDateColorClass() {
      return dueDateColorClass(this.task);
    },

    /**
     * due Date Class
     * @return {String|string|undefined}
     */
    dueDateTextClass() {
      return dueDateTextClass(this.task);
    },

    /**
     * due Date Message
     * @return {String|string}
     */
    dueDateMessage() {
      return dueDateMessage(this.task);
    },

    /**
     * visible Notes
     * @return {boolean} true if visible Notes
     */
    visibleNotes() {
      return !this.isReport && (this.task?.notes?.length ?? 0) > 0;
    },

    /**
     * visible ReAssign Button
     * @return {boolean} true if ReAssign Button is visible
     */
    visibleReAssignButton() {
      /**
       * handle report
       */
      if (this.isReport) {
        return false;
      }

      return canReassignTask(this.task);
    }
  },

  methods: {
    ...mapActions({
      assignUser: "assignUser"
    }),

    ...mapMutations({ updateTaskAssignee: UPDATE_TASK_ASSIGNEE }),

    /**
     * Show ReAssign Dialog
     */
    showReAssignDialog() {
      this.visibleDialogAssignUser = true;
    },

    /**
     * Close Dialog Assign User
     */
    onCloseAssignUserTaskDialog() {
      this.visibleDialogAssignUser = false;
    },

    /**
     * Event On Assign User
     * @param {String} userName
     * @param {Number} userId
     * @param {String} comment
     * @param {Boolean} notify
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onUserAssignment(userName, userId, comment, notify) {
      try {
        const operation = findProjectOperation(projectOperation.ReassignTask);
        const action = `${operation?.label}`;

        const payload = {
          taskId: this.taskId,
          userId: userId ?? -1,
          comment: comment ?? "",
          sendNotification: notify ?? false
        };
        const result = await this.assignUser(payload);
        if (result) {
          return createActionResultSuccess(action);
        } else return createActionResultError("User Reassignment");
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    }
  }
};
