<template>
  <div>
    <v-container fluid>
      <!-- task chart  -->
      <v-row dense>
        <task-chart :task="task"></task-chart>
      </v-row>

      <!-- task id, task name  -->
      <v-row dense>
        <!-- task id -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="taskId"
            label="Task Id"
            readonly
          ></v-text-field>
        </v-col>

        <!-- task name -->
        <v-col cols="12" md="6">
          <v-text-field readonly v-model="taskName" label="Task"></v-text-field>
        </v-col>
      </v-row>

      <!-- date time when task is Created, Effective -->
      <v-row dense>
        <!-- created date time -->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconCalendar"
            v-model="taskCreated"
            label="Created"
            readonly
          ></v-text-field>
        </v-col>

        <!-- effective date time -->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconCalendar"
            v-model="taskEffective"
            label="Effective"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- status -->
      <v-row dense>
        <!-- status -->
        <v-col cols="12" md="6">
          <v-text-field readonly v-model="taskStatus" label="Status">
          </v-text-field>
        </v-col>

        <!-- status changed -->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconCalendar"
            v-model="statusChanged"
            label="Status Changed"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- assignment -->
      <v-row dense>
        <!-- assignment Method -->
        <v-col cols="12" md="6">
          <v-text-field readonly v-model="assignmentMethod" label="Assignment">
          </v-text-field>
        </v-col>

        <!-- Assignee -->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconUser"
            readonly
            v-model="assignee"
            label="Assigned To"
          >
            <template v-slot:append v-if="visibleReAssignButton">
              <v-btn small icon @click="showReAssignDialog"
                ><v-icon>{{ iconMore }}</v-icon></v-btn
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!--  Priority & Duration -->
      <v-row dense>
        <!-- Priority -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="priorityText"
            label="Priority"
            readonly
          ></v-text-field>
        </v-col>

        <!-- task Duration -->
        <v-col cols="12" md="6" v-show="!isMilestone">
          <v-text-field readonly v-model="taskDuration" label="Duration">
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Due Date & (is Due &  is Overdue) -->
      <v-row dense>
        <!-- Due Date -->
        <v-col cols="12" md="6" v-show="!isMilestone">
          <v-text-field
            :prepend-icon="iconCalendar"
            :error-messages="dueDateMessage"
            readonly
            label="Due Date"
            v-model="dueDate"
          >
          </v-text-field>
        </v-col>

        <!-- is Due -->
        <v-col cols="12" md="3" v-show="isDue">
          <v-checkbox
            v-model="isDue"
            label="Due"
            readonly
            :color="dueDateColorClass"
          ></v-checkbox>
        </v-col>

        <!-- is Overdue -->
        <v-col cols="12" md="3" v-show="isOverdue">
          <v-checkbox
            v-model="isOverdue"
            label="Overdue"
            readonly
            :color="dueDateColorClass"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- Milestone -->
      <v-row dense v-show="isMilestone">
        <v-col cols="12" md="6">
          <v-checkbox
            readonly
            v-model="isMilestone"
            label="Milestone"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- notes -->
      <v-row dense v-if="visibleNotes">
        <v-col>
          <task-notes :notes="notes"></task-notes>
        </v-col>
      </v-row>
    </v-container>

    <!-- Assign User Dialog -->
    <assign-user-dialog
      :task="task"
      :visible="visibleDialogAssignUser"
      :perform-action="onUserAssignment"
      @close="onCloseAssignUserTaskDialog"
    ></assign-user-dialog>
  </div>
</template>

<script>
// mixins
import { taskMixin } from "@/mixins/shared/workflow/task/taskMixin";

export default {
  name: "ProjectTask",
  mixins: [taskMixin],
  components: {
    TaskChart: () => import("@/components/task/TaskChart")
  },
  props: {
    /**
     * Workflow Project
     * @type {{projectId: Number, projectName:string, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string, userTasks: {taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}}
     */
    project: undefined
  }
};
</script>
